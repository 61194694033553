// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */)
}

